import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SeoHelmet from "../components/seo-helmet";
import Sidebar from "../components/sidebar";
import Feed from "../components/feed";
import Page from "../components/page";

const CategoryTemplate = ({ data, pageContext }) => {
  const { nodes } = data.allMarkdownRemark;

  const { category } = pageContext;

  const pageTitle = category;

  return (
    <Layout>
      <SeoHelmet title={pageTitle} />
      <Sidebar />
      <Page>
        <Feed nodes={nodes} />
      </Page>
    </Layout>
  );
};

export default CategoryTemplate;

export const pageQuery = graphql`
  query CategoryPage($category: String) {
    allMarkdownRemark(
      filter: { frontmatter: { category: { in: [$category] } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      nodes {
        fields {
          slug
          categorySlug
        }
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          category
          description
        }
      }
    }
  }
`;
